import React from 'react'
import styled from 'styled-components'

export const ButtonWrapper = styled.button`
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  /* display: inline-block; */
  &:hover {
    transform: scale(1.1);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    transform: scale(1);
  }
`
function ScaleButton({ children, ...props }) {
  return (
    <ButtonWrapper className="" {...props}>
      {children}
    </ButtonWrapper>
  )
}

export default ScaleButton
