import Button from 'components/Button'
import YellowCard from 'components/Card/YellowCard'
import connectors from 'components/ModalConnect/config'
import WalletCard from 'components/ModalConnect/WalletCard'
import ScaleButton from 'components/ScaleButton'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import useAuth from 'hooks/useAuth'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { ButtonStyled } from 'style/Global'
import styled from 'styled-components'
import { Box } from '../../packages/uikit/src/components/Box'
import { useModal } from '../../packages/uikit/src/widgets/Modal'

const Tooltip = styled.div<{ isTooltipDisplayed: boolean }>`
  display: ${({ isTooltipDisplayed }) => (isTooltipDisplayed ? 'block' : 'none')};
  position: absolute;
  bottom: -22px;
  right: 0;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.contrast};
  color: ${({ theme }) => theme.colors.invertedContrast};
  border-radius: 16px;
  opacity: 0.7;
  padding: 0.25rem 1rem;
`
const ConnectTitle = styled.p`
  background: linear-gradient(270.18deg, #ff5eab 9.62%, #ff5775 88.74%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`
function Hello(props) {
  const { login, logout } = useAuth()
  const [isOpen, setIsOpen] = useState(false)
  const [shouldRedirect, setShouldRedirect]: any = useState(false)
  const { account } = useActiveWeb3React()
  const [isTooltipDisplayed, setIsTooltipDisplayed] = useState(false)
  const accountEllipsis = account ? `${account.substring(0, 4)}...${account.substring(account.length - 4)}` : undefined
  const router = useRouter()
  const { redirect }: any = router.query

  const handleClose = () => {
    setIsOpen(false)
  }
  const handleLogout = () => {
    logout()
    handleClose()
  }

  useEffect(() => {
    if (redirect && account && shouldRedirect) {
      router.push(redirect)
    }
  }, [account, redirect, router, shouldRedirect])

  return (
    <YellowCard>
      <div className="relative">
        <div className="p-4 md:p-8 flex flex-col items-center relative">
          <div className="relative">
            <img
              src="/images/hello/title-bg.png"
              className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 object-contain w-[125%] max-w-none h-auto"
              alt=""
            />
            <p className="font-Poppins text-[25px] font-semibold italic text-center uppercase relative text-white">
              <span> Choose your wallet</span>
              <br />
              <span> earn cash now!</span>
            </p>
          </div>
          <div className="my-8">
            <img src="/images/hello/human.png" width="248" alt="" />
          </div>
          {!!account && (
            <div className="flex items-center justify-center relative">
              <div>
                <img src="/images/green-cash.png" width="48" alt="" />
              </div>
              <div className="ml-2 border border-black border-solid bg-white flex items-stretch rounded w-[222px]">
                <div className="py-1 px-4 text-[13px] font-bold flex-1 flex items-center">{accountEllipsis}</div>
                <button
                  type="button"
                  onClick={() => {
                    if (navigator.clipboard) {
                      navigator.clipboard.writeText(account)
                      setIsTooltipDisplayed(true)
                      setTimeout(() => {
                        setIsTooltipDisplayed(false)
                      }, 1000)
                    }
                  }}
                  className="py-1 px-2 border-l border-black border-solid"
                >
                  <img src="/images/icons/copy.png" width="17" alt="" />
                </button>
              </div>
              <div className="flex justify-center">
                <Tooltip isTooltipDisplayed={isTooltipDisplayed}>Copied</Tooltip>
              </div>
            </div>
          )}
          <div className="flex items-center space-x-16 mt-4">
            {!account ? (
              <Button type="button" onClick={() => setIsOpen(true)}>
                Connect Wallet
              </Button>
            ) : (
              <>
                <Button type="button" onClick={() => router.push('/wallet')} width="150px">
                  Wallet
                </Button>
                <Button type="button" onClick={() => handleLogout()} width="150px">
                  Logout
                </Button>
              </>
            )}
          </div>
        </div>
        {isOpen && (
          <div className="absolute top-0 left-0 w-full h-full min-h-screen md:min-h-0 md:h-full flex justify-center items-center bg-[#00000099]">
            <div
              className="px-8 pt-16 text-black relative w-full h-full"
              style={{
                background: 'linear-gradient(180deg, #FEE3E5 0%, #FFF8F8 100%)',
              }}
            >
              <img src="/images/connect/left-bg.png" className="absolute bottom-0 left-0" alt="" />
              <img src="/images/connect/right-bg.png" className="absolute top-0 right-0" alt="" />
              <div className="relative">
                <ScaleButton
                  type="button"
                  onClick={() => handleClose()}
                  className="absolute right-0 -top-4 text-[20px] font-bold font-Space"
                >
                  <img src="/images/connect/close-btn.png" alt="" />
                </ScaleButton>
                <ConnectTitle className="text-[20px] font-bold font-Space">Connect Wallet</ConnectTitle>
                <div className="mt-8">
                  {connectors.map((entry) => (
                    <Box key={entry.title} mt="1rem" width="100%" maxWidth="468px">
                      <WalletCard
                        key={entry.title}
                        walletConfig={entry}
                        login={(connectorID) => {
                          login(connectorID)
                          handleClose()
                          setShouldRedirect(true)
                        }}
                        onDismiss={() => {
                          handleClose()
                        }}
                      />
                    </Box>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </YellowCard>
  )
}

Hello.fullLayout = true
export default Hello
