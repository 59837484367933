import React from 'react'
import styled from 'styled-components'
import { twMerge } from 'tailwind-merge'

const CardWrapper = styled.div`
  /* padding: 1rem; */
  position: relative;
  /* background: linear-gradient(to right, red, purple); */
  /* border: 1px solid; */

  background: linear-gradient(93.88deg, #7b6aeb 1.88%, #cb343c 102.87%);
`

const Content = styled.div`
  /* background: linear-gradient(180deg, #efdd3a 0%, #ffffff 100%); */
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
`

function YellowCard({ children, className = '', background = '', contentClass = '' }) {
  return (
    <CardWrapper
      className={twMerge('md:rounded-[30px] md:p-[4px] md:w-[389px] md:-mt-10 md:mb-16', [className].join(' '))}
    >
      <Content
        className={twMerge(
          ['md:rounded-[30px] overflow-hidden min-h-screen md:min-h-0 relative', contentClass].join(' '),
        )}
        style={{
          background,
        }}
      >
        {!background && (
          <img src="/images/common/card-bg.png" className="absolute top-0 left-0 w-full h-full object-fill" alt="" />
        )}
        {children}
      </Content>
    </CardWrapper>
  )
}

export default YellowCard
