import React from 'react'
import styled from 'styled-components'

export const ButtonWrapper = styled.button`
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  /* display: inline-block; */
  &:hover {
    transform: scale(1.1);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    transform: scale(1);
  }
`
function Button({ children, width = undefined, className = '', ...props }) {
  return (
    <ButtonWrapper className={['relative px-4 py-2', className].join(' ')} {...props}>
      <img
        src="/images/buttons/common-btn.png"
        alt=""
        style={{
          width: width || '120%',
        }}
        className="absolute top-1/2 left-1/2 max-w-none h-auto object-fill -translate-x-1/2 -translate-y-[38%]"
      />
      <div className="relative text-white font-bold text-lg">{children}</div>
    </ButtonWrapper>
  )
}

export default Button
